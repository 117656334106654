import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { forward } from 'base/history';
import { model } from '../service';
import { form } from '../../formWizard/service';
import EntryTable from './EntryTable';
import EntryRow from './EntryRow';
import IntersectionObserver from './IntersectionObserver';

const NUMBER_OF_HEADERS = 5;

class AllEntries extends React.PureComponent {
  componentWillMount() {
    const formId = this.getFormId();
    this.props.loadEntries(formId);
    this.props.loadForm(formId);
  }
  getFormId() {
    const { match: { params: { formId } } } = this.props;
    return formId;
  }

  getTableFields() {
    const { form } = this.props;
    if (!form.attributes || !form.attributes.fields) {
      return [];
    }
    const tableHeaders = form.attributes.fields.slice(0, NUMBER_OF_HEADERS);
    return tableHeaders;
  }

  openDetail = id => {
    const { match: { params: { projectId } } } = this.props;
    const formId = this.getFormId();
    this.props.forward(`/projects/${projectId}/forms/${formId}/entries/${id}`);
  };

  loadNextEntries = () => {
    if (!this.props.isPending && this.props.pagination.hasMore) {
      const formId = this.getFormId();
      this.props.loadMoreEntries(formId, this.props.currentOffset);
    }
  };

  renderEntryRows() {
    return this.props.entries.map(entry => (
      <EntryRow
        onClick={this.openDetail}
        key={entry.id}
        entry={entry}
        headers={this.getTableFields()}
      />
    ));
  }

  render() {
    return (
      <React.Fragment>
        <EntryTable headers={this.getTableFields()}>
          {this.renderEntryRows()}
          <IntersectionObserver onIntersect={this.loadNextEntries} />
        </EntryTable>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  form: form.selectors.selectData(state),
  entries: model.selectors.selectData(state),
  currentOffset: model.selectors.selectData(state).length,
  pagination: model.selectors.selectPagination(state),
  isEmpty: model.selectors.isEmpty(state),
  isPending: model.selectors.isPending(state)
});

const mapDispatchToProps = dispatch => ({
  loadForm: form.loadForm(dispatch),
  loadEntries: model.loadEntries(dispatch),
  loadMoreEntries: model.loadMoreEntries(dispatch),
  forward: forward(dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllEntries)
);
