import React from 'react';

import './appLayout.scss';

export const Content = ({ children }) => (
  <div className="content">{children}</div>
);

export const LowerContent = ({ children }) => (
  <div className="lower-content">{children}</div>
);

export default ({ children }) => <div className="appLayout">{children}</div>;
