import React from 'react';

import { useDrop } from 'react-dnd';

import classNames from 'classnames';

import './formField.scss';


function FieldDeleteZone(props) {

  const formTarget = {
    canDrop: props => !props.field,
    drop: (item, monitor) => {
      if (item.fieldId) {
        props.moveField(item.fieldId, -1);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    accept: 'dnd-field'
  };

  const [collectedProps, drop] = useDrop(formTarget)
  return (
    <div
      ref={drop}
      className={classNames(
        'formField',
        'defaultSize',
        {
          [`noDrop`]: collectedProps.canDrop
        },
        { [`isOver`]: collectedProps.isOver }
      )}
    />
  );
}

export default FieldDeleteZone;
