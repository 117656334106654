import React from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarTitle
} from '@rmwc/top-app-bar';

import { forward } from 'base/history';
import { form } from '../service';

const Header = ({ forward, match: { params: { projectId } }, currentForm }) => {
  const returnToOverview = () => {
    forward(`/projects/${projectId}`);
  };
  return (
    <TopAppBar>
      <TopAppBarRow>
        <TopAppBarSection>
          <TopAppBarNavigationIcon
            icon="arrow_back"
            onClick={returnToOverview}
          />
          <TopAppBarTitle>{currentForm.attributes && `${currentForm.attributes.name}: `}Formular</TopAppBarTitle>
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  );
};

const mapStateToProps = state => ({
  currentForm: form.selectors.selectData(state),
});

const mapDispatchToProps = dispatch => ({
  forward: forward(dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
