export default () => ({
  status: 200,
  data: {
    attributes: {
      name: '',
      emailSettings: {
        host: 'smtp.postmarkapp.com',
        port: 587,
        encryption: '-',
        smtpAuth: true,
        type: 'smtp',
        username: '',
        password: ''
      }
    }
  }
});
