import React from 'react';

import classNames from 'classnames';
import get from 'lodash.get';
import { connect } from 'formik';
import { FeedbackConsumer } from 'common/context/FeedbackContext';
import { TextField, TextFieldHelperText } from '@rmwc/textfield';

import './text.scss';

const Text = ({ formik, name, fullWidth = true, ...rest }) => {
  const value = get(formik.values, name);
  return (
    <FeedbackConsumer>
      {feedback => {
        const error = feedback[name];
        return (
          <div className="gfs-text-field">
            <TextField
              className={classNames({ fullWidth })}
              value={value === null ? '' : value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={name}
              invalid={!!error}
              {...rest}
            />
            {error && (
              <TextFieldHelperText persistent validationMsg>
                {error}
              </TextFieldHelperText>
            )}
          </div>
        );
      }}
    </FeedbackConsumer>
  );
};

export default connect(Text);
