import React from 'react';

import {connect as formikConnect, FieldArray} from 'formik';
import {connect as reduxConnect} from 'react-redux';

import {Button} from '@rmwc/button';
import {IconButton} from '@rmwc/icon-button';
import {Typography} from '@rmwc/typography';

import Flexbox from 'visuals/container/Flexbox';
import SaveButtonContainer from 'common/components/SaveButtonContainer';

import {FormContainer, FormTitleBar} from 'common/components/FormContainer';
import {Text, Select, Checkbox} from 'common/components/input';
import Tooltip from 'common/components/Tooltip';
import languages from '../../reference/languages';
import FailureIndicator from './FailureIndicator';
import FormWizardSection from '../FormWizardSection';
import StickyTitle from '../../../visuals/container/StickyTitle';
import {baseSelectors as mailTemplateSelectors} from '../../service/mailTemplates';

import {LANGUAGE_HANDLE_PLACEHOLDER} from '../../service/form'

const renderLanguages = () =>
  languages.map(language => (
    <div key={language.value}>
      <Checkbox name={`form.attributes.languages.${language.value}`}>
        {language.label}
      </Checkbox>
    </div>
  ));

const renderMailTemplateForLanguages = (
  currentLanguages,
  baseModel,
  {disabled = false} = {}
) =>
  Object.entries(currentLanguages)
    .filter(language => language[1])
    .map(language => (
      <div key={language[0]}>
        <Typography use="subtitle2">Sprache {language[0]}</Typography>
        <Text
          name={`${baseModel}.subject.${language[0]}`}
          label="Betreff"
          disabled={disabled}
        />
        <Text
          name={`${baseModel}.text.${language[0]}`}
          textarea
          label="Nachricht"
          disabled={disabled}
        />
        <Text
          name={`${baseModel}.footerText.${language[0]}`}
          textarea
          label="Nachricht (Fusszeile)"
          disabled={disabled}
        />
      </div>
    ));

const renderSuccessTemplateForLanguages = (currentLanguages, baseModel) =>
  Object.entries(currentLanguages)
    .filter(language => language[1])
    .map(language => (
      <div key={language[0]}>
        <Text
          name={`${baseModel}.${language[0]}`}
          textarea
          label={`Erfolgsmeldung ${language[0]}`}
        />
      </div>
    ));

const renderFieldHandleSelect = (
  model,
  attributes,
  {
    disabled = false,
    enableLanguageSelect = false,
  } = {}
) => {
  let options = [
    {
      label: 'Bitte auswählen',
      value: null
    },
    ...attributes.fields.map(field => ({
      label: field.handle,
      value: field.id
    }))
  ]

  if (enableLanguageSelect) {
    options.push({
      label: '(Sprache)',
      value: LANGUAGE_HANDLE_PLACEHOLDER,
    })
  }

  return <Tooltip overlay="Felder müssen zuerst gespeichert werden bevor sie als Referenz genutzt werden können.">
    <Select
      label="Feld"
      disabled={disabled}
      name={model}
      options={options}
    />
  </Tooltip>
};

const renderMailTemplateSelect = (model, items, {disabled = false} = {}) => (
  <Tooltip overlay="Felder müssen zuerst gespeichert werden bevor sie als Referenz genutzt werden können.">
    <Select
      label="E-Mail Template"
      disabled={disabled}
      name={model}
      options={[
        ...items.map(field => ({
          label: field.label,
          value: field.value
        }))
      ]}
    />
  </Tooltip>
);

const createBasicConditionElement = () => ({
  to: 'mail',
  condition: ['', '=', 'value']
});

const renderAdminMailConditions = (attributes, {disabled = false} = {}) => (
  <div>
    <FieldArray name="form.attributes.adminMail.sendTo.conditions">
      {arrayHelpers => (
        <React.Fragment>
          {attributes.adminMail.sendTo.conditions &&
          attributes.adminMail.sendTo.conditions.map((condition, idx) => (
            <div key={idx}>
              <h5>Regel</h5>

              {renderFieldHandleSelect(
                `form.attributes.adminMail.sendTo.conditions.${idx}.condition.0`,
                attributes,
                {
                  disabled: disabled,
                  enableLanguageSelect: true,
                }
              )}
              <Text
                disabled={disabled}
                name={`form.attributes.adminMail.sendTo.conditions.${idx}.condition.2`}
                label="Wert für Feld"
              />
              <Text
                disabled={disabled}
                name={`form.attributes.adminMail.sendTo.conditions.${idx}.to`}
                label="Empfänger"
              />
              <IconButton
                type="button"
                disabled={disabled}
                onClick={() => arrayHelpers.remove(idx)}
                label="Kondition entfernen"
                icon="delete"
              />
            </div>
          ))}
          {/* TODO: general data form shall be stateful */}
          <Button
            disabled={disabled}
            onClick={() => arrayHelpers.push(createBasicConditionElement())}
            type="button"
          >
            Neue Kondition
          </Button>
        </React.Fragment>
      )}
    </FieldArray>
  </div>
);

const GeneralDataForm = props => {
  const {
    onSubmit,
    formik: {values},
    mailTemplates
  } = props;

  const isSenderMailEnabled = values.form.attributes.senderMailEnabled;
  const isAdminMailEnabled = values.form.attributes.adminMailEnabled;
  const isDebugEnabled = values.form.attributes.debug;

  let mappedMailTemplates = []
  if (mailTemplates) {
    mappedMailTemplates = Object.values(mailTemplates).map(template => ({
      value: template.template,
      label: template.name
    }))
  }

  return (
    <FormContainer>
      <Flexbox type="form" column spaceBetween onSubmit={onSubmit}>
        <div>
          <FailureIndicator/>
          <FormTitleBar>
            <Typography use="headline6">Formular Basisdaten</Typography>
          </FormTitleBar>
          <Text name="form.attributes.name" label="Formular Name"/>

          <FormWizardSection>
            <StickyTitle>
              <Typography use="headline6">Sprachen</Typography>
            </StickyTitle>
            {renderLanguages()}
          </FormWizardSection>

          <FormWizardSection>
            <StickyTitle>
              <Typography use="headline6">Sender Mail Konfiguration</Typography>
            </StickyTitle>
            <Checkbox name="form.attributes.senderMailEnabled">
              Sender Mail enabled
            </Checkbox>
            {renderFieldHandleSelect(
              'form.attributes.senderMail.field',
              values.form.attributes,
              {disabled: !isSenderMailEnabled}
            )}
            {renderMailTemplateForLanguages(
              values.form.attributes.languages,
              'form.attributes.senderMail',
              {disabled: !isSenderMailEnabled}
            )}
            {renderMailTemplateSelect(
              'form.attributes.senderMail.mailTemplate',
              mappedMailTemplates,
              {disabled: !isSenderMailEnabled}
            )}
          </FormWizardSection>

          <FormWizardSection>
            <StickyTitle>
              <Typography use="headline6">Admin Mail Konfiguration</Typography>
            </StickyTitle>
            <Checkbox name="form.attributes.adminMailEnabled">
              Admin Mail enabled
            </Checkbox>
            {/* TODO @FE fix react error when clicking on the "sendFiles" checkbox */}
            <Checkbox
              name="form.attributes.adminMail.sendFiles"
              disabled={!isAdminMailEnabled}
            >
              Send files as attachment
            </Checkbox>
            {renderMailTemplateForLanguages(
              values.form.attributes.languages,
              'form.attributes.adminMail',
              {disabled: !isAdminMailEnabled}
            )}
            {renderMailTemplateSelect(
              'form.attributes.adminMail.mailTemplate',
              mappedMailTemplates,
              {disabled: !isAdminMailEnabled}
            )}
            <Text
              name="form.attributes.adminMail.sendTo.defaultMail"
              label="Default Mail Empfänger"
              disabled={!isAdminMailEnabled}
            />
            {renderAdminMailConditions(values.form.attributes, {
              disabled: !isAdminMailEnabled
            })}
          </FormWizardSection>

          <FormWizardSection>
            <StickyTitle>
              <Typography use="headline6">Absender</Typography>
            </StickyTitle>
            <Text name="form.attributes.from" label="Absender"/>
            <Typography use="subtitle1">Erfolgsmeldung</Typography>
            {renderSuccessTemplateForLanguages(
              values.form.attributes.languages,
              'form.attributes.successMessage'
            )}
          </FormWizardSection>

          <FormWizardSection>
            <StickyTitle>
              <Typography use="headline6">Debug</Typography>
            </StickyTitle>
            <Tooltip overlay="Wenn debug mode enabled, dann werden alle Mails and die ausgewählte Adresse geschickt.">
              <Checkbox name="form.attributes.debug">Debug enbabled</Checkbox>
            </Tooltip>
            <Text
              name="form.attributes.debugEmail"
              label="Debug Mail"
              disabled={!isDebugEnabled}
            />
          </FormWizardSection>
        </div>
        <SaveButtonContainer mt="xs">
          <Flexbox flexEnd p="xs">
            <Button type="submit" raised>
              Speichern
            </Button>
          </Flexbox>
        </SaveButtonContainer>
      </Flexbox>
    </FormContainer>
  );
};

const mapStateToProps = state => ({
  mailTemplates: mailTemplateSelectors.selectData(state).mailTemplates
});

export default reduxConnect(mapStateToProps)(formikConnect(GeneralDataForm));
