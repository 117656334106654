import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { List, ListDivider, ListGroup, ListGroupSubheader } from '@rmwc/list';

import { model } from '../service';

import InputType from './InputType';

function InputTypeBrowser (props) {
  useEffect(() => {
    props.loadFieldTypes();
  }, [])

  const renderInputTypes = () => {
    return props.fields.fieldType && (
      <ListGroup>
        <ListGroupSubheader>Inputtypes</ListGroupSubheader>
        {props.fields.fieldType.map(fieldType => (
          <InputType key={fieldType.id} fieldType={fieldType} />
        ))}
      </ListGroup>
    )
  }

  const renderInputPresets = () => {
    return props.fields.fieldPreset &&  (
      <ListGroup>
        <ListGroupSubheader>Presets</ListGroupSubheader>
        {props.fields.fieldPreset.map(fieldType => (
          <InputType key={fieldType.id} fieldType={fieldType} />
        ))}
      </ListGroup>
    )
  }

  return (
    <List nonInteractive>
      {renderInputPresets()}
      <ListDivider />
      {renderInputTypes()}
    </List>
  )
}

const mapStateToProps = state => ({
  fields: model.selectors.selectData(state).reduce((acc, field) => {
    if (!acc[field.type]) {
      acc[field.type] = [field]
    } else {
      acc[field.type].push(field)
    }
    return acc
  }, {})
});

const mapDispatchToProps = dispatch => ({
  loadFieldTypes: model.loadFieldTypes(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InputTypeBrowser);
