import { base, reducer as reducerHelper, selector } from 'rejuice-redux';
import { getOperation, createOperation } from 'base/requestHelper';
import cloneObject from 'common/helpers/cloneObject';

import languages from '../reference/languages';
import emptyForm from '../reference/emptyForm';

const TYPE = 'formWizard.form';

const FRONTEND_ID_PREFIX = '$SHALLOW_ID$';

export const LANGUAGE_HANDLE_PLACEHOLDER = '__language'

export const includesFrontendFieldPrefix = field =>
  field && field.includes(FRONTEND_ID_PREFIX);

const transformLanguagesForFrontend = backendLanguages => {
  let frontendLanguages = {};
  languages.forEach(language => {
    frontendLanguages = {
      ...frontendLanguages,
      [language.value]: backendLanguages.includes(language.value)
    };
  });
  return frontendLanguages;
};

const transformLanguagesForBackend = frontendLanguages =>
  Object.entries(frontendLanguages)
    .filter(frontendLanguage => frontendLanguage[1])
    .map(frontendLanguage => frontendLanguage[0]);

const transformFieldsForBackend = fields =>
  fields.map(field => ({
    ...field,
    id: field.id.includes(FRONTEND_ID_PREFIX) ? undefined : field.id
  }));

const transformFieldReferenceForFrontend = (field, fields) => {
  if (field === null) {
    return '';
  }
  if(field === LANGUAGE_HANDLE_PLACEHOLDER) {
    return LANGUAGE_HANDLE_PLACEHOLDER
  }
  const fieldReference = fields.find(refField => refField.handle === field);
  if (fieldReference === undefined) {
    console.error(
      'invalid data from backend received, could not map field handle ',
      field,
      fields
    );
    return '';
  }
  return fieldReference.id;
};

const transformFieldReferenceForBackend = (field, fields) => {
  if (!field || field.length === 0) {
    return '';
  }
  if(field === LANGUAGE_HANDLE_PLACEHOLDER) {
    return LANGUAGE_HANDLE_PLACEHOLDER
  }
  const fieldReference = fields.find(refField => refField.id === field);
  if (fieldReference === undefined) {
    console.warn(
      'invalid data from frontend, could not map field id ',
      field,
      fields
    );
    return '';
  }
  return fieldReference.handle;
};

const transformFormForFrontend = action => {
  const { data: { attributes } } = action;
  attributes.languages = transformLanguagesForFrontend(attributes.languages);

  attributes.senderMail.field = transformFieldReferenceForFrontend(
    attributes.senderMail.field,
    attributes.fields
  );

  attributes.adminMail.sendTo.conditions = attributes.adminMail.sendTo.conditions.map(
    rule => ({
      ...rule,
      condition: [
        transformFieldReferenceForFrontend(
          rule.condition[0],
          attributes.fields
        ),
        rule.condition[1],
        rule.condition[2]
      ]
    })
  );
  return action;
};

export const loadForm = dispatch => formId => {
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/forms/' + formId).then(
      base.transformOnSuccess(transformFormForFrontend)
    )
  });
};

export const createEmptyForm = dispatch => projectId => {
  dispatch({
    type: base.fulfilled(TYPE),
    payload: emptyForm(projectId)
  });
};

export const saveForm = dispatch => (token, { id, attributes }) => {
  const newAttributes = cloneObject(attributes);
  newAttributes.languages = transformLanguagesForBackend(attributes.languages);

  newAttributes.senderMail.field = transformFieldReferenceForBackend(
    newAttributes.senderMail.field,
    attributes.fields
  );
  newAttributes.adminMail.sendTo.conditions = attributes.adminMail.sendTo.conditions.map(
    rule => ({
      ...rule,
      condition: [
        transformFieldReferenceForBackend(rule.condition[0], attributes.fields),
        rule.condition[1],
        rule.condition[2]
      ]
    })
  );
  newAttributes.fields = transformFieldsForBackend(attributes.fields);
  dispatch({
    type: TYPE,
    payload: createOperation(
      `/api/v1/forms${id === undefined ? '' : `/${id}`}`,
      {
        ...newAttributes,
        _token: token
      }
    ).then(base.transformOnSuccess(transformFormForFrontend))
  });
};

export const setFormData = dispatch => formData => {
  dispatch({
    type: `${TYPE}_SET_FORM_DATA`,
    payload: formData
  });
};

const prepareSettings = settings => {
  const populatedSettings = {};
  Object.entries(settings).forEach(entry => {
    populatedSettings[entry[0]] = entry[1].default;
  });
  return populatedSettings;
};

export const moveField = dispatch => (fieldId, newIndex) => {
  dispatch({
    type: `${TYPE}_MOVE_FIELD`,
    payload: {
      fieldId,
      newIndex
    }
  });
};

// TODO: proper extraction
let id = 0;
const buildFeIdentifier = () => {
  ++id;
  return `${FRONTEND_ID_PREFIX}${id}`;
};

export const addField = dispatch => (index, type) => {
  dispatch({
    type: `${TYPE}_ADD_FIELD`,
    payload: {
      index,
      field: {
        id: buildFeIdentifier(),
        fieldType: type.attributes.fieldType,
        handle: type.attributes.handle || type.attributes.name,
        name: type.attributes.name,
        settings: prepareSettings(type.attributes.settings)
      }
    }
  });
};

const baseSelectors = selector.generateSelectors(
  state => state.formWizard.form,
  {}
);
export const selectors = {
  ...baseSelectors,
  selectFieldType: (state, fieldId) =>
    baseSelectors
      .selectData(state)
      .attributes.fields.find(field => field.id === fieldId) || {},
  selectLanguages: state => baseSelectors.selectData(state).attributes.languages
};

const baseReducer = reducerHelper.defaultReducer(TYPE);

const moveFieldReducer = (state, action) => {
  const fields = state.data.attributes.fields;
  const oldIndex = fields.findIndex(
    field => field.id === action.payload.fieldId
  );

  const { payload: { newIndex } } = action;
  const realIndex = oldIndex > newIndex ? newIndex : newIndex - 1;
  const item = fields.splice(oldIndex, 1)[0];
  if (realIndex !== -1) {
    fields.splice(realIndex, 0, item);
  }
  return {
    ...state,
    data: {
      ...state.data,
      attributes: {
        ...state.data.attributes,
        fields
      }
    }
  };
};

const setFormDataReducer = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      attributes: {
        ...action.payload
      }
    }
  };
};

/* eslint no-case-declarations: 0 */
export const reducer = (state, action) => {
  switch (action.type) {
    case `${TYPE}_ADD_FIELD`:
      const fields = state.data.attributes.fields;
      fields.splice(action.payload.index, 0, action.payload.field);
      return {
        ...state,
        data: {
          ...state.data,
          attributes: {
            ...state.data.attributes,
            fields
          }
        }
      };
    case `${TYPE}_MOVE_FIELD`:
      return moveFieldReducer(state, action);
    case `${TYPE}_SET_FORM_DATA`:
      return setFormDataReducer(state, action);
    default:
      return baseReducer(state, action);
  }
};
