import React from 'react';
import { connect } from 'react-redux';

import { model } from 'base/service/token';
import { model as sessionModel } from 'base/service/session';

class Token extends React.PureComponent {
  componentDidMount() {
    this.props.loadToken();
  }
  componentDidUpdate() {
    if (this.props.isEmpty && !this.props.isSessionPending) {
      this.props.loadToken();
    }
  }
  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  isSessionPending: sessionModel.selectors.isPending(state),
  isEmpty: model.selectors.isEmpty(state)
});

const mapDispatchToProps = dispatch => ({
  loadToken: model.loadToken(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Token);
