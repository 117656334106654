import React from 'react';

import { Tr, Td } from 'common/components/Table';

export default class ProjectRow extends React.PureComponent {
  onClick = () => {
    this.props.onClick(this.props.project.id);
  };

  render() {
    const {
      project: { id, attributes: { name, emailSettings: { host, username } } }
    } = this.props;
    return (
      <Tr onClick={this.onClick} isClickable>
        <Td>{id}</Td>
        <Td>{name}</Td>
        <Td>{host}</Td>
        <Td>{username}</Td>
      </Tr>
    );
  }
}
