import { reducer as reducerHelper, selector } from 'rejuice-redux';
import { getOperation } from 'base/requestHelper';

const TYPE = 'formWizard.model';

export const loadFieldTypes = dispatch => () => {
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/forms/fieldTypes')
  });
};

export const baseSelectors = selector.generateSelectors(
  state => state.formWizard.model,
  []
);

export const selectors = {
  ...baseSelectors,
  /*getFieldType: (state, searchedFieldTypeId) =>
    baseSelectors
      .selectData(state)
      .find(fieldType => fieldType.id === searchedFieldTypeId)*/
};

export const reducer = reducerHelper.defaultReducer(TYPE);
