import React from 'react'
import classnames from 'classnames'
import Box from './Box'

function Flexbox({
  wrap = false,
  column = false,
  flexEnd = false,
  centered = false,
  spaceBetween = false,
  grow = false,
  alignItemsCenter = false,
  swapMobileDirection = false,
  ...props
}) {
  return <Box {...props} className={classnames('flexbox-container', {
    '-column': column,
    '-flex-end': flexEnd,
    '-centered': centered,
    '-space-between': spaceBetween,
    '-grow': grow,
    '-wrap': wrap,
    '-align-items-center': alignItemsCenter,
    '-swap-mobile-direction': swapMobileDirection
  })}/>
}

export default Flexbox