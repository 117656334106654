import React from 'react';

import AppLayout, { Content, LowerContent } from 'layout/AppLayout';

import Header from './components/Header';
import FormEntries from './components/AllEntries';
import FormEntry from './components/CurrentFormEntry';

export default () => (
  <AppLayout>
    <Content>
      <Header />
      <LowerContent>
        <FormEntries />
      </LowerContent>
    </Content>
    <FormEntry />
  </AppLayout>
);
