const TYPE = 'formWizard.app';

export const setEditingType = dispatch => fieldId => {
  dispatch({
    type: `${TYPE}_SET`,
    fieldId
  });
};

const rootSelector = state => state.formWizard.app;

export const selectors = {
  selectData: rootSelector
};

export const reducer = (
  state = {
    fieldId: undefined
  },
  action
) => {
  switch (action.type) {
    case `${TYPE}_SET`:
      return {
        fieldId: action.fieldId
      };
    default:
      return state;
  }
};
