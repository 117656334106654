import React from 'react';

const { Consumer, Provider } = React.createContext({});

export const FeedbackProvider = ({ children, feedback }) => (
  <Provider value={feedback}>{children}</Provider>
);

export const FeedbackConsumer = ({ children }) => (
  <Consumer>{children}</Consumer>
);
