import React from 'react';

import { connect } from 'react-redux';

import { model as tokenModel } from 'base/service/token';
import { loadMailTemplates } from 'formWizard/service/mailTemplates';
import mapErrorsToFeedback from 'common/helpers/mapErrorsToFeedback';
import { FeedbackProvider } from 'common/context/FeedbackContext';
import { app, form } from '../../service';
import FormContext from './FormContext';

class FieldEditor extends React.PureComponent {

  componentDidMount = () => {
    this.props.loadMailTemplates();
  };
  onSubmit = ({ attributes }) => {
    this.props.setFormData(attributes);
  };
  onSave = values => {
    // if it is a non-persisted field the field id will change after save.
    // Therefore it must be reset before saving
    // TODO: should be only performed on successful response of server (in case of a validation error model will not be touched)
    if (form.includesFrontendFieldPrefix(this.props.fieldId)) {
      this.props.setEditingType();
    }
    this.props.saveForm(this.props.token, values.form);
  };
  render() {
    const { fieldId, form, errors } = this.props;
    if (this.props.isPending) {
      return <p>Loading...</p>;
    }
    return (
      <FeedbackProvider
        feedback={mapErrorsToFeedback(errors, 'form.attributes')}
      >
        <FormContext
          fieldId={fieldId}
          onSubmit={this.onSubmit}
          form={form}
          onSave={this.onSave}
        />
      </FeedbackProvider>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenModel.selectors.getToken(state),
  fieldId: app.selectors.selectData(state).fieldId,
  form: form.selectors.selectData(state),
  isPending: form.selectors.isPending(state),
  errors: form.selectors.selectErrorDetail(state)
});

const mapDispatchToProps = dispatch => ({
  setFormData: form.setFormData(dispatch),
  saveForm: form.saveForm(dispatch),
  setEditingType: app.setEditingType(dispatch),
  loadMailTemplates: loadMailTemplates(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldEditor);
