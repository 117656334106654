import { combineReducers } from 'redux';
import { getOperation } from 'base/requestHelper';
import { base, reducer, selector } from 'rejuice-redux';

export const TYPE = 'token';

const resetToken = dispatch => () => {
  dispatch({
    type: base.resetting(TYPE)
  });
};

const loadToken = dispatch => () => {
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/token')
  });
};

export const selectors = selector.generateSelectors(
  state => state.token.model,
  {}
);

export const model = {
  resetToken,
  loadToken,
  selectors: {
    ...selectors,
    getToken: state => selectors.selectData(state).token
  }
};

const modelReducer = reducer.defaultReducer(TYPE);

export default combineReducers({
  model: modelReducer
});
