import { createStore, combineReducers, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
// import thunkMiddleware from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

// import logger from 'redux-logger';
import session from 'base/service/session';
import token from 'base/service/token';

import projectList from 'projectList/service';
import project from 'project/service';
import formWizard from 'formWizard/service';
import formEntries from 'formEntries/service';

import history from 'base/history';

const isDev = process.env.NODE_ENV === 'development';

let middleware = applyMiddleware(
  // thunkMiddleware,
  promiseMiddleware(),
  routerMiddleware(history)
  // logger,
);

if (isDev) {
  middleware = composeWithDevTools(middleware);
}

export default createStore(
  combineReducers({
    router: routerReducer,
    session,
    token,
    projectList,
    project,
    formWizard,
    formEntries
  }),
  {},
  middleware
);
