import React from 'react';

import { Dialog } from '@reach/dialog';
import Icon from 'common/components/icon';
import { Tr, Td } from 'common/components/Table';
import Tooltip from 'common/components/Tooltip';
import { copyToClipboard } from '@goldinteractive/js-base/src/utils/clipboard';
import DeleteDialog from './DeleteDialog';

export default function FormRow(props) {
  const onIdClick = e => {
    e.stopPropagation();
    e.preventDefault();
    copyToClipboard(props.form.id);
  };

  const [showDialog, setShowDialog] = React.useState(false);
  const openDeleteDialog = () => setShowDialog(true);
  const closeDeleteDialog = () => setShowDialog(false);

  const openEntries = () => {
    props.openEntries(props.form.id);
  };

  const openEdit = () => {
    props.openEdit(props.form.id);
  };

  const {
    form: {
      id,
      attributes: { name, languages, from }
    },
    deleteForm
  } = props;
  return (
    <Tr>
      <Td>
        {id}
        <Tooltip overlay="Kopieren">
          <Icon onClick={onIdClick} icon="file_copy" small />
        </Tooltip>
      </Td>
      <Td>{name}</Td>
      <Td>{languages.join(', ')}</Td>
      <Td>{from}</Td>
      <Td>
        <Tooltip overlay="Einträge anschauen" placement="top">
          <Icon onClick={openEntries} icon="list" />
        </Tooltip>
      </Td>
      <Td>
        <Tooltip overlay="Felder bearbeiten" placement="top">
          <Icon onClick={openEdit} icon="edit" />
        </Tooltip>
      </Td>
      <Td>
        <Tooltip overlay="Formular löschen" placement="top">
          <Icon onClick={openDeleteDialog} icon="delete" />
        </Tooltip>
      </Td>
      <Dialog isOpen={showDialog} onDismiss={closeDeleteDialog}>
        <DeleteDialog
          formName={name}
          close={closeDeleteDialog}
          deleteForm={deleteForm}
        />
      </Dialog>
    </Tr>
  );
}
