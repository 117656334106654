import { combineReducers } from 'redux';

import * as model from './model';
import * as form from './form';
import * as app from './app';
import * as mailTemplates from './mailTemplates';

export { model };
export { form };
export { app };
export { mailTemplates };

export default combineReducers({
  model: model.reducer,
  form: form.reducer,
  app: app.reducer,
  mailTemplates: mailTemplates.reducer
});
