import React from 'react';

import Date from 'common/components/Date';

import { DataTableRow, DataTableCell } from '@rmwc/data-table';

export default class EntryRow extends React.PureComponent {
  onClick = () => {
    this.props.onClick(this.props.entry.id);
  };
  render() {
    const { entry, headers } = this.props;
    return (
      <DataTableRow onClick={this.onClick}>
        <DataTableCell>
          <Date dateString={entry.attributes.createdAt} />
        </DataTableCell>
        {headers.map(header => (
          <DataTableCell
            key={header.id}
            dangerouslySetInnerHTML={{
              __html: entry.attributes.fields[header.handle]
            }}
          ></DataTableCell>
        ))}
      </DataTableRow>
    );
  }
}
