export default [
  {
    label: 'Keine',
    value: '-'
  },
  {
    label: 'TLS',
    value: 'tls'
  },
];
