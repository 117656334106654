import React from 'react';

import GeneralDataForm from './GeneralDataForm';

class GeneralData extends React.PureComponent {
  onSubmit = e => {
    e.preventDefault()
    this.props.onSubmit();
  };
  render() {
    return (
      <GeneralDataForm
        onSubmit={this.onSubmit}
        form={this.props.form}
      />
    );
  }
}

export default GeneralData;
