import { reducer as reducerHelper, selector } from 'rejuice-redux';
import { getOperation } from 'base/requestHelper';

const TYPE = 'projectList.model';

export const loadProjects = dispatch => () => {
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/projects')
  });
};

const baseSelectors = selector.generateSelectors(
  state => state.projectList.model,
  []
);
export const selectors = {
  ...baseSelectors
};

export const reducer = reducerHelper.defaultReducer(TYPE);
