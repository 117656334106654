import React from 'react';

import { Button } from '@rmwc/button';

import Flexbox from 'visuals/container/Flexbox';
import Box from 'visuals/container/Box';
import { Typography } from '@rmwc/typography';

function DeleteDialog({ formName, close, deleteForm }) {
  return (
    <Flexbox column p="xs">
      <Typography use="headline6" tag="h3">
        Formular löschen
      </Typography>
      <Typography use="body2" tag="p">
        "{formName}" mit sämtlichen Einträgen wird endgültig gelöscht.
      </Typography>
      <Flexbox mt="xs">
        <Box mr="xs">
          <Button onClick={close}>Abbrechen</Button>
        </Box>
        <Button raised danger icon="delete_forever" onClick={deleteForm}>
          Löschen
        </Button>
      </Flexbox>
    </Flexbox>
  );
}

export default DeleteDialog;
