import React from 'react';

import get from 'lodash.get';
import { connect } from 'formik';
import { Select } from '@rmwc/select';
import { FeedbackConsumer } from 'common/context/FeedbackContext';
import InputError from './InputError';

import './select.scss';

const WrappedSelect = ({ formik, name, ...rest }) => (
  <FeedbackConsumer>
    {feedback => {
      const error = feedback[name];
      return (
        <div className="gfs-select">
          <Select
            value={get(formik.values, name)}
            name={name}
            onChange={formik.handleChange}
            {...rest}
          />
          <InputError error={error} />
        </div>
      );
    }}
  </FeedbackConsumer>
);

export default connect(WrappedSelect);
