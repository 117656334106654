import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { model as tokenModel } from 'base/service/token';
import { replace } from 'base/history';
import { FeedbackProvider } from 'common/context/FeedbackContext';
import mapErrorsToFeedback from 'common/helpers/mapErrorsToFeedback';
import { form } from '../service/project';

import ProjectForm from './project/ProjectForm';

const CREATE_IDENTIFIER = 'create';

class CurrentProjectForm extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params: { projectId } } } = props;

    props.resetForm();
    if (projectId !== CREATE_IDENTIFIER) {
      props.loadProject(projectId);
    } else {
      props.createEmptyForm();
    }
    this.state = {
      isInitialLoad: true
    };
  }

  componentDidUpdate() {
    const { project, match: { params: { projectId } } } = this.props;
    if (
      project &&
      project.id !== undefined &&
      projectId === CREATE_IDENTIFIER
    ) {
      this.props.replace(`/projects/${project.id}`);
    }
    if (!this.props.isPending && this.state.isInitialLoad !== false) {
      this.setState({
        isInitialLoad: false
      });
    }
  }

  onSubmit = data => {
    this.props.saveProject(this.props.token, data);
  };

  render() {
    const { isPending, isEmpty, project, errors } = this.props;
    if ((isPending && this.state.isInitialLoad) || project === null) {
      return <p>Loading...</p>;
    }
    return (
      <FeedbackProvider
        feedback={mapErrorsToFeedback(errors, 'project.attributes')}
      >
        <ProjectForm
          key={project.id}
          onSubmit={this.onSubmit}
          project={project}
        />
      </FeedbackProvider>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenModel.selectors.getToken(state),
  isPending: form.selectors.isPending(state),
  isEmpty: form.selectors.isEmpty(state),
  project: form.selectors.selectData(state),
  errors: form.selectors.selectErrorDetail(state)
});

const mapDispatchToProps = dispatch => ({
  loadProject: form.loadProject(dispatch),
  saveProject: form.saveProject(dispatch),
  resetForm: form.resetForm(dispatch),
  createEmptyForm: form.createEmptyForm(dispatch),
  replace: replace(dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CurrentProjectForm)
);
