import { base, reducer as reducerHelper, selector } from 'rejuice-redux';
import { createOperation, getOperation } from 'base/requestHelper';
import emptyProject from '../../reference/emptyProject'

const TYPE = 'project.project.form';

export const loadProject = dispatch => projectId => {
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/projects/' + projectId)
  });
};

export const saveProject = dispatch => (token, { id, attributes}) => {
  dispatch({
    type: TYPE,
    payload: createOperation(
      `/api/v1/projects${id === undefined ? '' : `/${id}`}`,
      {
        ...attributes,
        _token: token
      }
    )
  });
};

export const setFormData = dispatch => formData => {
  dispatch({
    type: `${TYPE}_SET_FORM_DATA`,
    payload: formData
  });
};

export const resetForm = dispatch => () => {
  dispatch({
    type: base.resetting(TYPE)
  });
}

export const createEmptyForm = dispatch => () => {
  dispatch({
    type: base.fulfilled(TYPE),
    payload: emptyProject()
  });
};

const baseSelectors = selector.generateSelectors(
  state => state.project.project.form,
  null
);
export const selectors = {
  ...baseSelectors
};

export const baseReducer = reducerHelper.defaultReducer(TYPE);

const setFormDataReducer = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      attributes: {
        ...action.payload
      }
    }
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case `${TYPE}_SET_FORM_DATA`:
      return setFormDataReducer(state, action);
    default:
      return baseReducer(state, action);
  }
};