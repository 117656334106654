import React from 'react';

import { connect } from 'formik';
import { connect as connectStore } from 'react-redux';

import { Button } from '@rmwc/button';
import { IconButton } from '@rmwc/icon-button';
import { Typography } from '@rmwc/typography';

import Tooltip from 'common/components/Tooltip';
import Flexbox from 'visuals/container/Flexbox';
import { FormContainer, FormTitleBar } from 'common/components/FormContainer';
import { Text } from 'common/components/input';
import SaveButtonContainer from 'common/components/SaveButtonContainer';

import { withStoreUpdate } from '../../context/FormWizardContext';
import { app, model } from '../../service';

import SettingForm from './SettingForm';
import FailureIndicator from './FailureIndicator';

/**
 * Renders the settings of a selected field type
 */
class FieldType extends React.Component {
  componentDidUpdate() {
    const {
      formik: { values }
    } = this.props;
    const { index } = this.getFieldEntry();
    const fieldInput = values.form.attributes.fields[index];
    // in case a input type has been deleted and is no longer available
    // it must redirect to the general form
    if (fieldInput === undefined && !this.redirecting) {
      this.redirecting = true;
      this.goToGeneralForm();
    }
  }

  getFieldEntry() {
    const {
      fieldId,
      formik: { values }
    } = this.props;
    const index = values.form.attributes.fields.findIndex(
      field => fieldId === field.id
    );
    return {
      index,
      field: values.form.attributes.fields[index]
    };
  }

  goToGeneralForm = () => {
    this.props.updateStore(() => {
      this.props.setEditingType();
    });
  };

  renderSettings(settings) {
    const {
      fieldTypes,
      formik: { values }
    } = this.props;
    const { index, field } = this.getFieldEntry();
    const fieldType = fieldTypes.find(type => type.id === field.fieldType);
    return Object.entries(settings)
      .filter(setting => fieldType.attributes.settings[setting[0]].visible)
      .map(setting => (
        <SettingForm
          key={setting[0]}
          baseModel={`form.attributes.fields.${index}.settings.${setting[0]}`}
          settingKey={setting[0]}
          settingType={fieldType.attributes.settings[setting[0]]}
          setting={setting[1]}
          languages={values.form.attributes.languages}
        />
      ));
  }

  render() {
    const { onSubmit, fieldId } = this.props;
    const { index, field } = this.getFieldEntry();
    if (field === undefined) {
      return null;
    }
    return (
      <FormContainer key={fieldId}>
        <Flexbox type="form" column spaceBetween onSubmit={onSubmit}>
          <div>
            <FailureIndicator />
            <FormTitleBar>
              <IconButton
                onClick={this.goToGeneralForm}
                icon="close"
                label="Zurück zur Übersicht"
                type="button"
              />
              <Typography use="headline6">{field.name}</Typography>
            </FormTitleBar>
            <Tooltip overlay="Handle wird im HTML Formular verwendet. Verwende im HTML immer fields[handle]! Konvention ist Camel Case.">
              <Text
                type="text"
                name={`form.attributes.fields.${index}.handle`}
                label="Handle"
              />
            </Tooltip>
            <Tooltip overlay="Erscheint im E-Mail als Name des Feldes">
              <Text
                type="text"
                name={`form.attributes.fields.${index}.name`}
                label="Name"
              />
            </Tooltip>
            {field.settings && this.renderSettings(field.settings)}
          </div>
          <SaveButtonContainer mt="xs">
            <Flexbox flexEnd p="xs">
              <Button type="submit" raised>
                Speichern
              </Button>
            </Flexbox>
          </SaveButtonContainer>
        </Flexbox>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  // TODO: extract the setting type selection logic properly...
  fieldTypes: model.selectors.selectData(state)
});

const mapDispatchToProps = dispatch => ({
  setEditingType: app.setEditingType(dispatch)
});

export default connect(
  connectStore(mapStateToProps, mapDispatchToProps)(withStoreUpdate(FieldType))
);
