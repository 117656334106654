import React from 'react';

import Table, { Thead, Tbody, Tr, Th } from 'common/components/Table';

export default ({ children }) => (
  <Table>
    <Thead>
      <Tr>
        <Th>Id</Th>
        <Th>Projekt</Th>
        <Th>Host</Th>
        <Th fullWidth>Username</Th>
      </Tr>
    </Thead>
    <Tbody>{children}</Tbody>
  </Table>
);
