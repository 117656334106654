import contextPathResolver from './contextPathResolver';

const contextPath = contextPathResolver();

export const getFormHeaders = () => ({
  'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
});

const encode = text => encodeURIComponent(text);

const encodeEntry = (entry, unencodedPrefix, isArray = false) => {
  const key = encode(entry[0]);
  const prefix = encode(unencodedPrefix);
  let stringifiedKey = key;
  if (unencodedPrefix) {
    stringifiedKey = `${prefix}[${key}]`;
  }
  if (isArray) {
    // if it is an array, multiple params have to be generated
    stringifiedKey = `${stringifiedKey}[]`;
    return entry[1].map(value => `${stringifiedKey}=${value}`).join('&');
  }
  const value = encode(entry[1]);
  return `${stringifiedKey}=${value}`;
};

export const toFormData = item => {
  const str = [];
  for (const p in item) {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(item[p])}`);
  }
  return str.join('&');
};

export const toURIFormat = (obj, prefix) =>
  Object.entries(obj)
    .map(entry => {
      const key = entry[0];
      const value = entry[1];
      if (Array.isArray(value)) {
        return encodeEntry(entry, prefix, true);
      }
      if (typeof value === 'object') {
        return toURIFormat(value, key);
      }
      return encodeEntry(entry, prefix);
    })
    .join('&');

const internalServerError = () =>
  new Promise(resolve =>
    resolve({
      status: 500
    })
  );

export default async (endpoint, params) => {
  const options = {
    mode: 'cors',
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    ...params
  };

  let response;
  try {
    response = await fetch(contextPath + endpoint, options);
  } catch (fetchException) {
    console.warn(
      'failed to fetch',
      contextPath + endpoint,
      options.method,
      fetchException
    );
    return internalServerError();
  }

  // TODO: content validation
  try {
    return response.json();
  } catch (jsonParseError) {
    console.warn(
      'could not parse: ',
      contextPath + endpoint,
      options.method,
      jsonParseError
    );
    return internalServerError();
  }
};
