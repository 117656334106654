import React from 'react';

import classNames from 'classnames';
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableCell
} from '@rmwc/data-table';

import './table.scss';

export const Thead = ({ children }) => (
  <DataTableHead>{children}</DataTableHead>
);
export const Tbody = ({ children }) => (
  <DataTableBody>{children}</DataTableBody>
);
export const Tr = ({ children, isClickable, ...rest }) => (
  <DataTableRow
    className={classNames({
      ['isClickable']: isClickable
    })}
    {...rest}
  >
    {children}
  </DataTableRow>
);
export const Th = ({ children, fullWidth }) => (
  <DataTableHeadCell
    className={classNames({
      ['fullWidth']: fullWidth
    })}
  >
    {children}
  </DataTableHeadCell>
);
export const Td = ({ children }) => <DataTableCell>{children}</DataTableCell>;

export default ({ children }) => (
  <DataTable>
    <DataTableContent>{children}</DataTableContent>
  </DataTable>
);
