import React from 'react';
import { Icon } from '@rmwc/icon';

function IconWrapper({ icon, small = false, ...rest }) {
  let style = {
    cursor: 'pointer'
  };
  if (small) {
    style = {
      ...style,
      marginLeft: '10px',
      fontSize: '0.8rem'
    };
  }
  return (
    <Icon
      icon={{
        style,
        icon,
        size: small ? 'xsmall' : undefined
      }}
      {...rest}
    />
  );
}

export default IconWrapper;
