import React from 'react';

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle
} from '@rmwc/top-app-bar';

import AppLayout, { Content, LowerContent } from 'layout/AppLayout';

import ProjectTable from './components/AllProjects';

export default () => (
  <AppLayout>
    <Content>
      <TopAppBar>
        <TopAppBarRow>
          <TopAppBarSection>
            <TopAppBarTitle>Projekte</TopAppBarTitle>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <LowerContent>
        <ProjectTable />
      </LowerContent>
    </Content>
  </AppLayout>
);
