import React from 'react';

import { connect, FieldArray } from 'formik';
import get from 'lodash.get';
import { Button, ButtonIcon } from '@rmwc/button';

import { Text, Checkbox } from 'common/components/input';

import './settingTypes.scss';

const primitives = {
  bool: ({ fieldPrefix }) => {
    return <Checkbox key={fieldPrefix} name={fieldPrefix} />;
  },
  number: ({ fieldPrefix, label = '' }) => (
    <Text type="number" key={fieldPrefix} name={fieldPrefix} label={label} />
  ),
  text: ({ fieldPrefix, label = '' }) => (
    <Text type="text" key={fieldPrefix} name={fieldPrefix} label={label} />
  ),
  multilinetext: ({ fieldPrefix, label = '' }) => (
    <Text textarea outlined type="text" key={fieldPrefix} name={fieldPrefix} label={label} />
  )
};

class FieldDefinition {
  constructor(id, primitive) {
    if (id === undefined) {
      this.isObject = true;
      this.items = {};
      if (primitive !== undefined) {
        throw new Error(
          'tried to create a plain object with a primitive, give it a name'
        );
      }
    }
    this.id = id;

    this.modifiable = true;
    if (primitive !== undefined) {
      this.primitive = primitive;
      this.modifiable = false;
    }
  }
  typeCheck() {
    if (!this.modifiable) {
      throw new Error('tried to alter already settled type definition');
    }
  }
  objectTypeCheck() {
    if (this.isObject) {
      throw new Error('tried to define a object as list or i18n');
    }
  }
  notObjectTypeCheck() {
    if (!this.isObject) {
      throw new Error(
        'tried to define a list or i18n element as a plain object'
      );
    }
  }
  freeze() {
    this.modifiable = false;
  }
  setItem(item) {
    if (item instanceof FieldDefinition) {
      this.item = item;
    } else {
      this.primitive = item;
    }
  }
  isList(item) {
    if (item === undefined) {
      return this.list;
    }
    this.typeCheck();
    this.objectTypeCheck();
    this.setItem(item);
    this.list = true;
    this.freeze();
    return this;
  }
  isI18n(item) {
    if (item === undefined) {
      return this.i18n;
    }
    this.typeCheck();
    this.objectTypeCheck();
    this.setItem(item);
    this.i18n = true;
    this.freeze();
    return this;
  }

  child(item) {
    this.notObjectTypeCheck();
    this.items[item.id] = item;

    return this;
  }

  isPlainObject() {
    return this.isObject;
  }
}

const registerFieldType = (typeIdentifier, primitive) =>
  new FieldDefinition(typeIdentifier, primitive);

const buildField = (fieldType, primitive) =>
  new FieldDefinition(fieldType, primitive);

const buildObject = () => new FieldDefinition();

const renderFieldDefinition = ({
  type,
  languages,
  setting,
  settingType,
  fieldPrefix = '',
  parentPrefix = ''
}) => {
  if (type === undefined) {
    console.error('got undefined type', fieldPrefix);
    throw new Error('got undefined type: ' + fieldPrefix);
  }
  if (!(type instanceof FieldDefinition)) {
    return type({
      fieldPrefix,
      label: fieldPrefix.replace(parentPrefix, '')
    });
  }
  if (type.isPlainObject()) {
    return Object.entries(type.items).map(entry => {
      const key = entry[0];
      const value = entry[1];

      return renderFieldDefinition({
        type: value,
        languages,
        setting,
        settingType,
        parentPrefix: fieldPrefix,
        fieldPrefix: `${fieldPrefix}.${key}`
      });
    });
  }
  if (type.isI18n() && settingType.i18n) {
    return (
      Object.entries(languages)
        // only show selected languages
        .filter(languageEntry => languageEntry[1])
        .map(language => (
          <div key={language[0]}>
            {renderFieldDefinition({
              type: type.item || type.primitive,
              languages,
              setting,
              settingType,
              parentPrefix: fieldPrefix,
              fieldPrefix:
                fieldPrefix.length > 0
                  ? `${fieldPrefix}.${language[0]}`
                  : language[0]
            })}
          </div>
        ))
    );
  }
  if (type.primitive) {
    return type.primitive({
      fieldPrefix: fieldPrefix === '' ? 'value' : fieldPrefix,
      label: fieldPrefix.replace(parentPrefix, '')
    });
  }

  if (type.isList()) {
    return (
      <EntriesExtended
        fieldPrefix={fieldPrefix}
        type={type}
        languages={languages}
        setting={setting}
        settingType={settingType}
      />
    );
  }
  console.error('unhandled type', type, fieldPrefix);
  throw new Error('unhandled type with fieldPrefix: ' + fieldPrefix);
};

/* eslint react/prefer-stateless-function: 0 */
class Entries extends React.Component {
  render() {
    const {
      formik: { values },
      fieldPrefix,
      type,
      languages,
      setting,
      settingType
    } = this.props;
    const fieldValues = get(values, fieldPrefix);
    return (
      <FieldArray name={`${fieldPrefix}`}>
        {arrayHelpers => (
          <React.Fragment>
            {fieldValues.map((value, idx) => (
              <div key={idx} className="listItem">
                {renderFieldDefinition({
                  type: type.item,
                  languages,
                  setting,
                  settingType,
                  parentPrefix: fieldPrefix,
                  fieldPrefix: `${fieldPrefix}.${idx}`
                })}
                <Button type="button" onClick={() => arrayHelpers.remove(idx)}>
                  <ButtonIcon icon="delete" />
                </Button>
              </div>
            ))}
            <Button type="button" onClick={() => arrayHelpers.push({})}>
              Neues Element
            </Button>
          </React.Fragment>
        )}
      </FieldArray>
    );
  }
}

const EntriesExtended = connect(Entries);

const options = registerFieldType('options').isList(
  buildObject()
    .child(buildField('value', primitives.text))
    .child(buildField('labels').isI18n(primitives.text))
);

const bool = registerFieldType('bool', primitives.bool);
const number = registerFieldType('number', primitives.number);
const text = registerFieldType('text').isI18n(primitives.text);
const multilinetext = registerFieldType('multilinetext').isI18n(primitives.multilinetext);

const wrapFieldDefinition = type => ({
  setting,
  languages,
  settingType,
  fieldPrefix
}) =>
  renderFieldDefinition({
    type,
    languages,
    setting,
    settingType,
    fieldPrefix
  });

export default {
  bool: wrapFieldDefinition(bool),
  number: wrapFieldDefinition(number),
  text: wrapFieldDefinition(text),
  multilinetext: wrapFieldDefinition(multilinetext),
  options: wrapFieldDefinition(options)
};
