import React from 'react';

import get from 'lodash.get';
import { connect } from 'formik';
import { Checkbox } from '@rmwc/checkbox';
import { FeedbackConsumer } from 'common/context/FeedbackContext';
import InputError from './InputError';

import './checkbox.scss';

const Text = ({ formik, name, children, ...rest }) => (
  <FeedbackConsumer>
    {feedback => {
      const error = feedback[name];
      return (
        <div className="checkbox">
          <Checkbox
            checked={get(formik.values, name) || false}
            name={name}
            onChange={e => formik.setFieldValue(name, e.target.checked)}
            onBlur={formik.handleBlur}
            {...rest}
          >
            {children}
          </Checkbox>
          <InputError error={error} />
        </div>
      );
    }}
  </FeedbackConsumer>
);

export default connect(Text);
