import React from 'react';

import { DrawerContent } from '@rmwc/drawer';
import Drawer from 'visuals/container/Drawer'

import AppLayout, { Content, LowerContent } from 'layout/AppLayout';

import AllForms from './components/AllForms';
import CurrentProjectForm from './components/CurrentProjectForm';
import Header from './components/Header';

export default () => (
  <AppLayout>
    <Content>
      <Header />
      <LowerContent>
        <AllForms />
      </LowerContent>
    </Content>
    <Drawer>
      <DrawerContent>
        <CurrentProjectForm />
      </DrawerContent>
    </Drawer>
  </AppLayout>
);
