import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { forward } from 'base/history';

import { Select } from '@rmwc/select';

import { model as projectsModel } from '../../projectList/service';
import { model as projectModel } from '../../project/service/duplicateForm';
import { model as tokenModel } from 'base/service/token';

import Flexbox from 'visuals/container/Flexbox';
import Box from 'visuals/container/Box';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';

import classNames from 'classnames';

import './duplicateForm.scss';

function DuplicateForm(props) {
  const [selectedProject, selectProject] = useState('');
  const [selectedForm, selectForm] = useState('');

  const targetProjectId = props.match.params.projectId;

  useEffect(() => {
    props.loadProjects();
  }, []);

  useEffect(() => {
    if (selectedProject) {
      props.loadForms(selectedProject);
    }
  }, [selectedProject]);

  if (props.projects.length === 0) return 'Loading';

  function duplicateForm() {
    props
      .duplicateForm(props.token, targetProjectId, selectedForm)
      .then(response => {
        const formId = response.data.id;
        props.forward(`/projects/${targetProjectId}/forms/${formId}`);
      });
  }
  return (
    <Flexbox column p="xs">
      <Box mb="sm">
        <Typography use="headline6" tag="h3">
          Formular kopieren
        </Typography>
        <Typography use="body2" tag="p">
          Wähle ein Projekt aus und daraus ein Formular. Dieses Formular wird
          ohne die Einträge darin in das aktive Projekt kopiert.
        </Typography>
      </Box>
      <Flexbox column>
        <Box mb="sm">
          <Select
            className={classNames('select')}
            label="Projekt"
            value={selectedProject}
            onChange={e => selectProject(e.target.value)}
            placeholder="Bitte auswählen"
            options={props.projects.map(project => ({
              label: project.attributes.name,
              value: project.id
            }))}
          />
        </Box>
        <Box mb="sm">
          {selectedProject && (
            <>
              <Select
                className={classNames('select')}
                label="Formular"
                onChange={e => selectForm(e.target.value)}
                placeholder="Bitte auswählen"
                value={selectedForm}
                options={props.forms.map(form => ({
                  label: form.attributes.name,
                  value: form.id
                }))}
              />
            </>
          )}
        </Box>
        <Box mb="sm">
          {selectedForm && (
            <Button onClick={duplicateForm} label="Duplicate" raised />
          )}
        </Box>
      </Flexbox>
    </Flexbox>
  );
}

const mapStateToProps = state => ({
  projects: projectsModel.selectors.selectData(state),
  forms: projectModel.selectors.selectData(state),
  isEmpty: projectsModel.selectors.isEmpty(state),
  token: tokenModel.selectors.getToken(state)
});

const mapDispatchToProps = dispatch => ({
  loadProjects: projectsModel.loadProjects(dispatch),
  loadForms: projectModel.loadForms(dispatch),
  duplicateForm: projectModel.duplicateForm,
  forward: forward(dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DuplicateForm)
);
