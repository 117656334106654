import request from 'common/helpers/request';
import { base, statusCode } from 'rejuice-redux';
import store from 'base/store';
import { model } from 'base/service/session';
import { model as tokenModel } from 'base/service/token';

const operationBuilder = async (
  endpoint,
  { isHandled, ...options },
  additionalInfo
) => {
  const data = await request(endpoint, options);

  if (!isHandled) {
    // TODO: custom middleware?
    // isHandled means that custom action will be performed if user is not authenticated (used in session handling e.g.)
    // TODO: Handle 419 nicer with a nice overlay. But for now it's enough.
    if (data.status === 419) {
      tokenModel.loadToken(store.dispatch)();
      alert('Der Aufruf war nicht erfolgreich. Versuche es noch einmal.');
    } else if (statusCode.isUnauthenticated(data.status)) {
      // reset session since it's now invalid
      model.resetSession(store.dispatch)();
      // reload token since the old one has been invalidated
      tokenModel.loadToken(store.dispatch)();
      // redirect to login page to re-establish a proper session
      model.redirectToLogin(store.dispatch)();
    } else if(data.status === 500) {
      alert('Es ist ein Fehler aufgetreten (500): ' + data.message)
    }
  }

  return {
    ...data,
    ...additionalInfo
  };
};

export const customOperation = (endpoint, options, operation) =>
  operationBuilder(endpoint, options, { operation });

export const getOperation = (endpoint, options) =>
  operationBuilder(
    endpoint,
    {
      ...options
    },
    { operation: base.OPERATION.GET }
  );

export const createOperation = (endpoint, body, options) =>
  operationBuilder(
    endpoint,
    {
      body: JSON.stringify(body),
      ...options,
      method: 'POST'
    },
    { operation: base.OPERATION.CREATE }
  );

export const postOperation = (endpoint, options) =>
  operationBuilder(endpoint, {
    ...options,
    method: 'POST'
  });

export const deleteOperation = (endpoint, body, options) =>
  operationBuilder(
    endpoint,
    {
      body: JSON.stringify(body),
      ...options,
      method: 'DELETE'
    },
    { operation: base.OPERATION.DELETE }
  );
