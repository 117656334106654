import React from 'react';

const { Consumer, Provider } = React.createContext({});

export class FormWizardContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateStore: this.updateStore
    };
    this.updateStoreCallback = () => {}
  }
  updateStore = (callback) => {
    this.callback = callback
    this.updateStoreCallback()
    
  }
  updateStoreHandler = updateStore => {
    this.updateStoreCallback = updateStore
  };
  /**
   * updateStore is async, so we must know when it's finished to perform actions only after
   * it has finished running the internal checks
   */
  callAfterStoreUpdate = () => {
    if (this.callback && typeof this.callback === 'function') {
      this.callback()
    }
  }
  render() {
    const { children } = this.props;
    return (
      <Provider
        value={{
          updateStoreHandler: this.updateStoreHandler,
          callAfterStoreUpdate: this.callAfterStoreUpdate,
          updateStore: this.state.updateStore
        }}
      >
        {children}
      </Provider>
    );
  }
}

export const withStoreUpdateHandler = WrappedComponent => {
  return class Wrapper extends React.Component {
    render() {
      const props = this.props;
      return (
        <Consumer>
          {({ updateStoreHandler, callAfterStoreUpdate }) => (
            <WrappedComponent
              updateStoreHandler={updateStoreHandler}
              callAfterStoreUpdate={callAfterStoreUpdate}
              {...props}
            />
          )}
        </Consumer>
      );
    }
  };
};

export const withStoreUpdate = WrappedComponent => {
  return class Wrapper extends React.Component {
    render() {
      const props = this.props;
      return (
        <Consumer>
          {({ updateStore }) => (
            <WrappedComponent updateStore={updateStore} {...props} />
          )}
        </Consumer>
      );
    }
  };
};
