export default [
  {
    label: 'Deutsch',
    value: 'de'
  },
  {
    label: 'Englisch',
    value: 'en'
  },
  {
    label: 'Französisch',
    value: 'fr'
  },
  {
    label: 'Italienisch',
    value: 'it'
  },
  {
    label: 'Spanisch',
    value: 'es'
  },
  {
    label: 'Portugiesisch',
    value: 'pt'
  },
  {
    label: 'Koreanisch',
    value: 'ko'
  },
  {
    label: 'Chinesisch',
    value: 'zh'
  },
  {
    label: 'Indonesisch',
    value: 'id'
  }
];
