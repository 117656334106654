import React from 'react';

import { Typography } from '@rmwc/typography';

import Tooltip from 'common/components/Tooltip';

import settingTypes from './settingTypes';
import FormWizardSection from '../FormWizardSection';
import StickyTitle from '../../../visuals/container/StickyTitle';

const FormEntry = ({ type, setting, languages, settingType, baseModel }) =>
  settingTypes[type]({
    setting,
    languages,
    settingType,
    fieldPrefix: baseModel
  });

class SettingForm extends React.PureComponent {
  onChange = values => {
    this.props.onChange(this.props.settingKey, values);
  };

  renderEntries() {
    const { setting, settingType, languages, baseModel } = this.props;
    return (
      <FormEntry
        type={settingType.type}
        settingType={settingType}
        setting={setting}
        languages={languages}
        baseModel={baseModel}
      />
    );
  }

  render() {
    const {
      setting,
      settingType: { label, instruction },
      settingKey
    } = this.props;
    const title = (
      <StickyTitle>
        <Typography use="subtitle2">{label || settingKey}:</Typography>
      </StickyTitle>
    );
    return (
      <FormWizardSection>
        {instruction ? <Tooltip overlay={instruction}>{title}</Tooltip> : title}
        {this.renderEntries()}
      </FormWizardSection>
    );
  }
}

export default SettingForm;
