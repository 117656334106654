import React from 'react';

import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

import { FeedbackConsumer } from 'common/context/FeedbackContext';
import Tooltip from 'common/components/Tooltip';

import './failureIndicator.scss';

const FailureIndicator = () => (
  <FeedbackConsumer>
    {feedback => {
      const feedbackEntries = Object.entries(feedback);
      const hasFeedback = feedbackEntries.length > 0;
      return hasFeedback ? (
        <Tooltip
          placement="left"
          overlay={() => (
            <table className="gfs-failure-indicator-table">
              <tbody>
                {feedbackEntries.map(([key, msg]) => (
                  <tr key={key}>
                    <td className="title-column">
                      <Typography use="subtitle2">{key}</Typography>
                    </td>
                    <td>
                      <Typography use="body2">{msg}</Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        >
          <div className="gfs-failure-indicator">
            <Icon icon="error" style={{ color: 'red' }} />
          </div>
        </Tooltip>
      ) : null;
    }}
  </FeedbackConsumer>
);

export default FailureIndicator;
