import React, { useEffect } from 'react';
import { Dialog } from '@reach/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { forward } from 'base/history';
import FixedFabs from 'visuals/container/FixedFabs';
import { Fab } from '@rmwc/fab';
import { model } from '../service/form';
import { model as tokenModel } from 'base/service/token';

import FormTable from './form/FormTable';
import FormRow from './form/FormRow';

import DuplicateForm from './DuplicateForm';

const CREATE_IDENTIFIER = 'create';

function AllForms(props) {
  const {
    match: {
      params: { projectId }
    }
  } = props;

  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  useEffect(() => {
    if (projectId !== CREATE_IDENTIFIER) {
      props.loadForms(projectId);
    }
  }, []);

  useEffect(() => {
    props.loadForms(projectId);
  }, [projectId]);

  const openEdit = formId => {
    props.forward(`/projects/${projectId}/forms/${formId}`);
  };

  const openCreate = () => {
    props.forward(`/projects/${projectId}/forms/create/${projectId}`);
  };

  const openEntries = formId => {
    props.forward(`/projects/${projectId}/forms/${formId}/entries`);
  };

  const renderFormRows = () => {
    return props.forms.map(form => (
      <FormRow
        key={form.id}
        form={form}
        openEdit={openEdit}
        openEntries={openEntries}
        deleteForm={() => props.deleteForm(props.token, form.id)}
      />
    ));
  };

  return (
    projectId !== CREATE_IDENTIFIER && (
      <React.Fragment>
        <FormTable>{renderFormRows()}</FormTable>
        <FixedFabs>
          <Fab onClick={openCreate} cssOnly icon="add" />
          <Fab onClick={open} cssOnly icon="file_copy" />
        </FixedFabs>
        <Dialog isOpen={showDialog} onDismiss={close}>
          <DuplicateForm />
        </Dialog>
      </React.Fragment>
    )
  );
}

const mapStateToProps = state => ({
  forms: model.selectors.selectData(state),
  token: tokenModel.selectors.getToken(state)
});

const mapDispatchToProps = dispatch => ({
  loadForms: model.loadForms(dispatch),
  deleteForm: model.deleteForm(dispatch),
  forward: forward(dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AllForms)
);
