import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { forward } from 'base/history';
import { model } from 'base/service/session';
import { model as tokenModel } from 'base/service/token';

import LoginForm from './components/LoginForm';

function Login(props) {
  useEffect(() => {
    if (props.isLoggedIn) {
      props.forward('/projects');
    }
  }, [props.isLoggedIn])

  const login = (email, password) => {
    props.login(props.token, email, password);
  };

  return (<LoginForm onSubmit={login} feedback={props.error} />)
}

const mapStateToProps = state => ({
  error: model.selectors.selectErrorDetail(state),
  token: tokenModel.selectors.getToken(state),
  isLoggedIn: model.selectors.isLoggedIn(state)
});

const mapDispatchToProps = dispatch => ({
  login: model.login(dispatch),
  forward: forward(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
