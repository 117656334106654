import React from 'react';
import { DragSource, useDrag } from 'react-dnd';

import { ListItem, ListItemGraphic } from '@rmwc/list';

import { withStoreUpdate } from '../context/FormWizardContext';

import './inputType.scss';

function InputType(props) {
  const {
    fieldType: { attributes: { name } }
  } = props;
  const [collectedProps, drag] = useDrag({
    item: { type: 'new-dnd-field', fieldType: props.fieldType },
  })
  return (
    <div ref={drag} className="gfs-input-type">
      <ListItem ripple={false}>
        <ListItemGraphic icon="drag_indicator" />
        {name}
      </ListItem>
    </div>
  );
}

export default withStoreUpdate(InputType);
