import React from 'react'
import classNames from 'classnames'

function Box({
  className,
  children, type= 'div',
  butFirst = false,
  butLast = false,
  ...props
}) {
  const propsToClassNames = () => {
    return Object.keys(props)
      .filter(key => key.match(/^(m|p)(r|l|t|b)?$/))
      .map(propKey => {
        const spaceClass = propKey.split('').join('-')
        return `${spaceClass}-${props[propKey]}`
      })
  }
  return React.createElement(
    type,
    {
      className: classNames(className, propsToClassNames(), {
        '--but-first': butFirst,
        '--but-last': butLast
      }),
      ...props
    },
    children
  )
}

export default Box
