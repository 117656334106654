import React from 'react';

import { Route } from 'react-router-dom';
import Session from 'base/Session';

export default class SessionRoute extends Route {
  render() {
    return <Session>{super.render()}</Session>;
  }
}
