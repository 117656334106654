import React from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarTitle
} from '@rmwc/top-app-bar';

import { forward } from 'base/history';

import contextPathResolver from '../../common/helpers/contextPathResolver';

const Header = ({ forward, match: { params: { projectId, formId } } }) => {
  const contextPath = contextPathResolver();

  const returnToOverview = () => {
    forward(`/projects/${projectId}`);
  };

  const exportData = () => {
    window.open(`${contextPath}/api/v1/forms/${formId}/export `, '_blank')
  }

  return (
    <TopAppBar>
      <TopAppBarRow>
        <TopAppBarSection>
          <TopAppBarNavigationIcon
            icon="arrow_back"
            onClick={returnToOverview}
          />
          <TopAppBarTitle>Einträge</TopAppBarTitle>
        </TopAppBarSection>
        <TopAppBarSection alignEnd>
          <TopAppBarNavigationIcon
            icon="file_download"
            onClick={exportData} />
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  forward: forward(dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
