import React from 'react';

import { connect } from 'react-redux';

import { forward } from 'base/history';
import FixedFab from 'common/components/FixedFab';
import { model } from '../service';
import ProjectTable from './ProjectTable';
import ProjectRow from './ProjectRow';

class AllProjects extends React.PureComponent {
  componentWillMount() {
    this.props.loadProjects();
  }

  openDetail = projectId => {
    this.props.forward(`/projects/${projectId}`);
  };

  openCreate = () => {
    this.props.forward('/projects/create');
  };

  renderProjectRows() {
    return this.props.projects.map(project => (
      <ProjectRow
        key={project.id}
        project={project}
        onClick={this.openDetail}
      />
    ));
  }

  render() {
    return (
      <React.Fragment>
        <ProjectTable>{this.renderProjectRows()}</ProjectTable>
        <FixedFab onClick={this.openCreate} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projects: model.selectors.selectData(state),
  isEmpty: model.selectors.isEmpty(state)
});

const mapDispatchToProps = dispatch => ({
  loadProjects: model.loadProjects(dispatch),
  forward: forward(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AllProjects);
