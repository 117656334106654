import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Drawer from 'visuals/container/Drawer';

import {
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent
} from '@rmwc/drawer';

import { replace, forward } from 'base/history';

import { form } from '../../formWizard/service';
import { model } from '../service';

import FormEntry from './FormEntry';

import './formEntryDrawer.scss';

class CurrentFormEntry extends React.Component {
  componentDidUpdate() {
    const {
      hasSucceeded,
      replace,
      location: { pathname },
      match: {
        params: { entryId }
      }
    } = this.props;
    if (
      hasSucceeded &&
      this.findEntry() === undefined &&
      entryId !== undefined
    ) {
      const pathParts = pathname.split('/');
      pathParts.pop(); // remove entryId from path (which is last fragment)
      const newPath = pathParts.join('/');
      replace(newPath);
    }
  }
  onClose = () => {
    const {
      forward,
      match: {
        params: { formId, projectId }
      }
    } = this.props;
    forward(`/projects/${projectId}/forms/${formId}/entries`);
  };
  findEntry() {
    const {
      entries,
      match: {
        params: { entryId }
      }
    } = this.props;
    const entryIdAsInt = parseInt(entryId, 10);
    return entries.find(entry => entry.id === entryIdAsInt);
  }
  render() {
    const { form } = this.props;
    const entry = this.findEntry();
    if (entry === undefined || form === undefined) {
      return null;
    }
    return (
      <div dir="rtl" key={entry.id}>
        <Drawer
          modal
          open
          dir="ltr"
          onClose={this.onClose}
          className="gfs-form-entry-drawer"
        >
          <DrawerHeader>
            <DrawerTitle>Eintrag</DrawerTitle>
            <DrawerSubtitle>#{entry.id}</DrawerSubtitle>
          </DrawerHeader>
          <DrawerContent>
            <FormEntry entry={entry} fields={form.attributes.fields} />
          </DrawerContent>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hasSucceeded: model.selectors.hasSucceeded(state),
  entries: model.selectors.selectData(state),
  form: form.selectors.selectData(state)
});

const mapDispatchToProps = dispatch => ({
  replace: replace(dispatch),
  forward: forward(dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CurrentFormEntry)
);
