import React from 'react';

import { withFormik } from 'formik';

import { withStoreUpdateHandler, withStoreUpdate } from '../../context/FormWizardContext';
import FieldType from './FieldType';
import GeneralData from './GeneralData';

class FormContext extends React.Component {
  componentDidMount() {
    this.props.updateStoreHandler(this.props.handleSubmit)
  }
  onSubmit = () => {
    const { values } = this.props
    this.props.updateStore(() => {
      this.props.onSave(values)
    })
  }
  render() {
    const { fieldId, form } = this.props;
    return fieldId ? (
      <FieldType key={fieldId} onSubmit={this.onSubmit} fieldId={fieldId} />
    ) : (
      <GeneralData form={form} onSubmit={this.onSubmit} />
    );
  }
}

export default withStoreUpdateHandler(withStoreUpdate(withFormik({
  enableReinitialize: true,
  handleSubmit: ({ form }, { props }) => {
    props.callAfterStoreUpdate()
    props.onSubmit(form);
  }
})(FormContext)));
