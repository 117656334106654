import {
  reducer as reducerHelper,
  selector,
  base,
  statusCode
} from 'rejuice-redux';
import { getOperation, deleteOperation } from 'base/requestHelper';
import { toURIFormat } from 'common/helpers/request';

const TYPE = 'project.form.model';
const deleteType = `${TYPE}.delete`;

export const loadForms = dispatch => projectId => {
  const params = {
    filter: {
      projectId
    }
  };
  const uriParams = toURIFormat(params);
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/forms?' + uriParams)
  });
};

export const deleteForm = dispatch => (token, formId) => {
  dispatch({
    type: deleteType,
    payload: deleteOperation('/api/v1/forms/' + formId, { _token: token }),
    meta: { deletedFormId: formId }
  });
};

const baseSelectors = selector.generateSelectors(
  state => state.project.form.model,
  []
);
export const selectors = {
  ...baseSelectors
};

export const reducer = (state, action) => {
  switch (action.type) {
    case base.fulfilled(deleteType):
      return {
        ...state,
        data: state.data.filter(form => form.id !== action.meta.deletedFormId),
        status: statusCode.isOk(action.payload.status)
          ? base.succeed(action.payload.operation)
          : base.fail(action.payload)
      };
    case base.pending(deleteType):
      return {
        ...state,
        status: base.update()
      };
    case base.rejected(deleteType):
      return {
        ...state,
        status: base.fail(action.payload)
      };
    default:
      return reducerHelper.defaultReducer(TYPE)(state, action);
  }
};
