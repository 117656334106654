import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';

import history from 'base/history';
import store from 'base/store';

import Token from 'base/Token';
import SessionRoute from 'base/SessionRoute';

import ProjectList from 'projectList/ProjectList';
import Project from 'project/Project';
import FormWizard from 'formWizard/FormWizard';
import Login from 'login/Login';
import FormEntries from 'formEntries/FormEntries';

export default () => (
  <Provider store={store}>
    <Token>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <SessionRoute path="/projects" component={ProjectList} exact />
          <SessionRoute path="/projects/:projectId" component={Project} exact />
          <SessionRoute
            path="/projects/:projectId/forms/:formId/entries/:entryId?"
            component={FormEntries}
          />
          <SessionRoute
            path="/projects/:projectId/forms/:formId/:projectId"
            component={FormWizard}
          />
          <SessionRoute
            path="/projects/:projectId/forms/:formId"
            component={FormWizard}
          />
          <Redirect from="/" to="/projects" />
        </Switch>
      </ConnectedRouter>
    </Token>
  </Provider>
);
