export default projectId => ({
  status: 200,
  data: {
    type: 'form',
    id: undefined,
    attributes: {
      name: '',
      from: 'noreply@goldinteractive.ch',
      projectId,
      languages: { de: true },
      senderMailEnabled: false,
      senderMail: {
        field: 'email',
        subject: { de: 'Deutscher Titel' },
        text: { de: 'Deutscher Text' },
        footerText: { de: 'Deutscher Text' },
        mailTemplate: 'default'
      },
      adminMailEnabled: true,
      adminMail: {
        sendTo: {
          defaultMail: '',
          conditions: []
        },
        subject: {
          de: 'Admin Deutscher Titel'
        },
        text: { de: 'Admin Deutscher Text' },
        footerText: { de: 'Deutscher Text' },
        mailTemplate: 'default'
      },
      successMessage: {
        de: 'Vielen Dank für Ihre Mail.'
      },
      fields: [],
      debug: false,
      debugEmail: ''
    }
  }
});
