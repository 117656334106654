import React from 'react';
import { connect } from 'react-redux';

import { LinearProgress } from '@rmwc/linear-progress';
import { Typography } from '@rmwc/typography';
import { model } from 'base/service/session';
import { model as tokenModel } from 'base/service/token';

class Session extends React.PureComponent {
  componentDidMount() {
    if (!this.props.isLoggedIn && !this.props.isPending) {
      this.props.loadSession();
    }
  }
  componentDidUpdate() {
    // if the session load has failed, the token is invalid and a new one must be fetched
    if (this.props.hasFailed) {
      this.props.loadToken();
    }
    if (!this.props.isLoggedIn && !this.props.isPending) {
      this.props.redirectToLogin();
    }
  }
  render() {
    if (this.props.isPending) {
      return <LinearProgress determinate={false} />;
    } else if (!this.props.isLoggedIn) {
      return <Typography use="title">Redirecting...</Typography>;
    }
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  isLoggedIn: model.selectors.isLoggedIn(state),
  isPending: model.selectors.isPending(state),
  hasFailed: model.selectors.hasFailed(state)
});

const mapDispatchToProps = dispatch => ({
  loadSession: model.loadSession(dispatch),
  redirectToLogin: model.redirectToLogin(dispatch),
  loadToken: tokenModel.loadToken
});

export default connect(mapStateToProps, mapDispatchToProps)(Session);
