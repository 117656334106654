import { combineReducers } from 'redux';
import { push } from 'react-router-redux';
import { base, reducer, selector } from 'rejuice-redux';
import {
  getOperation,
  postOperation,
  customOperation
} from 'base/requestHelper';
import { toFormData, getFormHeaders } from 'common/helpers/request';

const TYPE = 'session_model';

const login = dispatch => (token, email, password) => {
  dispatch({
    type: TYPE,
    payload: postOperation('/api/v1/login', {
      headers: getFormHeaders(),
      body: toFormData({
        _token: token,
        email,
        password
      })
    })
  });
};

const resetSession = dispatch => () => {
  dispatch({
    type: base.resetting(TYPE)
  });
};

const loadSession = dispatch => () => {
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/session', { isHandled: true })
  });
};

const logout = dispatch => () => {
  dispatch({
    type: TYPE,
    payload: customOperation(
      '/api/v1/logout',
      { method: 'GET' },
      base.OPERATION.DELETE
    )
  });
};

const redirectToLogin = dispatch => () => {
  // setSessionRoute(dispatch)(location.hash.replace('#', ''));
  dispatch(push('/login'));
};

const baseSelectors = selector.generateSelectors(state => state.session.model);
const selectors = {
  ...baseSelectors,
  isLoggedIn: state =>
    baseSelectors.selectData(state) !== null &&
    baseSelectors.selectData(state).attributes !== undefined,
  permissions: state =>
    baseSelectors.selectData(state) === null
      ? []
      : baseSelectors.selectData(state).attributes.permissions
};

export const model = {
  resetSession,
  loadSession,
  login,
  logout,
  redirectToLogin,
  selectors
};

const modelReducer = reducer.defaultReducer(TYPE);

export default combineReducers({
  model: modelReducer
});
