import React from 'react';

import Table, { Thead, Tbody, Tr, Th } from 'common/components/Table';

export default ({ children }) => (
  <Table>
    <Thead>
      <Tr>
        <Th>Id</Th>
        <Th>Formular</Th>
        <Th>Sprache</Th>
        <Th fullWidth>Mail Absender</Th>
        <Th />
        <Th />
      </Tr>
    </Thead>
    <Tbody>{children}</Tbody>
  </Table>
);
