import React from 'react';

import './formContainer.scss';

export const FormContainer = ({ children }) => (
  <div className="form-container">{children}</div>
);

export const FormTitleBar = ({ children }) => (
  <div className="title-bar">{children}</div>
);

export const FormActionBar = ({ children }) => (
  <div className="action-bar">{children}</div>
);
