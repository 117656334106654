import React from 'react';

// TODO: polyfill Intersection Observer

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.intersectionElement = React.createRef();
    this.intersectionObserver = new IntersectionObserver(
      this.handleIntersection
    );
  }
  componentDidMount() {
    this.intersectionObserver.observe(this.intersectionElement.current);
  }
  componentWillUnmount() {
    this.intersectionObserver.unobserve(this.intersectionElement.current);
    this.intersectionObserver = null;
  }
  handleIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.props.onIntersect();
      }
    });
  }
  render() {
    return <tr ref={this.intersectionElement} />;
  }
}
