import { combineReducers } from 'redux';

import project from './project';
import form from './form';
import duplicateForm from './duplicateForm';

export default combineReducers({
  project,
  form,
  duplicateForm
});
