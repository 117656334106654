import React from 'react';

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow
} from '@rmwc/data-table';

export default ({ children, headers }) => (
  <DataTable>
    <DataTableContent style={{ width: '100%' }}>
      <DataTableHead>
        <DataTableRow>
          <DataTableHeadCell>Datum</DataTableHeadCell>
          {headers.map(header => (
            <DataTableHeadCell key={header.id}>{header.name}</DataTableHeadCell>
          ))}
        </DataTableRow>
      </DataTableHead>
      <DataTableBody>{children}</DataTableBody>
    </DataTableContent>
  </DataTable>
);
