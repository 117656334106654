import React from 'react';

import { Typography } from '@rmwc/typography';

export default ({ entry, fields }) => (
  <dl className="gfs-form-entry">
    {fields.map(field => (
      <React.Fragment key={field.id}>
        <dt className="">
          <Typography use="subtitle2">{field.name}:</Typography>
        </dt>
        <dd>
          <Typography
            use="body2"
            dangerouslySetInnerHTML={{
              __html: entry.attributes.fields[field.handle]
            }}
          ></Typography>
        </dd>
      </React.Fragment>
    ))}
  </dl>
);
