import React from 'react';

import { useDrop } from 'react-dnd';

import classNames from 'classnames';

import './formField.scss';

function EmptyFieldType(props) {
  const formTarget = {
    canDrop: props => !props.field,
    drop: (item) => {
      if (item.fieldId) {
        props.moveField(item.fieldId, props.index);
      } else {
        props.addField(props.index, item.fieldType);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    accept: ['new-dnd-field', 'dnd-field']
  };

  const [{ canDrop, isOver }, drop] = useDrop(formTarget)
  return (
    <div
      ref={drop}
      className={classNames(
        'formField',
        'small',
        {
          [`dropActive`]: canDrop
        },
        { [`isOver`]: isOver }
      )}
    />
  );
}

export default EmptyFieldType;
