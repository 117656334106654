import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { List, SimpleListItem } from '@rmwc/list';

import { replace } from 'base/history';
import { withStoreUpdate } from '../context/FormWizardContext';
import { app, form } from '../service';

import FormField from './FormField';
import EmptyFieldType from './EmptyFieldType';
import FieldDeleteZone from './FieldDeleteZone';

import './formVisualizer.scss';

const CREATE_IDENTIFIER = 'create';

class FormVisualizer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { match: { params: { formId, projectId } } } = props;
    if (formId === CREATE_IDENTIFIER) {
      props.createEmptyForm(projectId);
    } else {
      props.loadForm(formId);
    }
  }

  componentDidUpdate() {
    const { form, match: { params: { formId, projectId } } } = this.props;
    if (form && form.id !== undefined && formId === CREATE_IDENTIFIER) {
      this.props.replace(`/projects/${projectId}/forms/${form.id}`);
    }
  }

  getEmptyFormField(key, index) {
    return (
      <EmptyFieldType
        key={key}
        addField={this.props.addField}
        moveField={this.props.moveField}
        index={index}
      />
    );
  }

  openEditView = fieldId => {
    this.props.updateStore(() => {
      this.props.setFieldId(fieldId);
    });
  };

  renderFormFields() {
    return this.props.form.attributes.fields.reduce(
      (aggregate, field, index) =>
        aggregate.concat(
          <FormField
            key={field.id || index}
            onClick={this.openEditView}
            field={field}
          />,
          this.getEmptyFormField(`${index}-additional`, index + 1)
        ),
      [this.getEmptyFormField('initial', 0)]
    );
  }

  render() {
    return (
      <div className="gfs-form-visualizer">
        <List twoLine nonInteractive>
          <FieldDeleteZone moveField={this.props.moveField} />
          {this.props.form.attributes && this.renderFormFields()}
          <FieldDeleteZone moveField={this.props.moveField} />
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  form: form.selectors.selectData(state)
});

const mapDispatchToProps = dispatch => ({
  loadForm: form.loadForm(dispatch),
  createEmptyForm: form.createEmptyForm(dispatch),
  setFieldId: app.setEditingType(dispatch),
  addField: form.addField(dispatch),
  moveField: form.moveField(dispatch),
  replace: replace(dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStoreUpdate(FormVisualizer))
);
