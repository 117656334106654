import React from 'react';

import { withFormik } from 'formik';
import { Button } from '@rmwc/button';
import { Text } from 'common/components/input';

import SimpleLayout, { Content } from 'layout/SimpleLayout';

const LoginForm = ({ handleSubmit, feedback }) => (
  <SimpleLayout>
    <Content>
      <form onSubmit={handleSubmit}>
        <h1>Gold Form Server</h1>
        <p>Bitte verwende die aktuellste Version von Google Chrome.</p>
        <Text label="Mail Adresse" name="email" />
        <Text label="Passwort" type="password" name="password" />
        {feedback && <div className="inputError">{feedback}</div>}

        <Button type="submit" raised>
          Anmelden
        </Button>
      </form>
    </Content>
  </SimpleLayout>
);

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: ''
  }),
  handleSubmit: ({ email, password }, { setSubmitting, props }) => {
    props.onSubmit(email, password);
    setSubmitting(false);
  }
})(LoginForm);
