import {
  reducer as reducerHelper,
  selector,
  base,
  statusCode
} from 'rejuice-redux';
import { getOperation } from 'base/requestHelper';

const TYPE = 'formEntries.model';

const LIMIT = 50;

const extend = type => `${type}.extend`;

export const loadEntries = dispatch => formId => {
  dispatch({
    type: TYPE,
    payload: getOperation(
      `/api/v1/forms/${formId}/entries?filter[offset]=0&filter[limit]=${LIMIT}`
    )
  }).then(base.callOnSuccess((data) => {
    dispatch({
      type: `${TYPE}.initialPagination`,
      payload: data.value.pagination
    })
  }));
};

export const loadMoreEntries = dispatch => (formId, offset) => {
  dispatch({
    type: extend(TYPE),
    payload: getOperation(
      `/api/v1/forms/${formId}/entries?filter[offset]=${offset}&filter[limit]=${LIMIT}`
    )
  });
};

const rootSelector = state => state.formEntries.model;

const baseSelectors = selector.generateSelectors(rootSelector, []);
export const selectors = {
  selectPagination: state => rootSelector(state).pagination,
  ...baseSelectors
};

const baseReducer = reducerHelper.defaultReducer(TYPE);

const extendFormReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
  case `${TYPE}.initialPagination`:
    return {
      ...state,
      pagination: payload
    }
  case base.fulfilled(extend(TYPE)):
    return {
      ...state,
      data: [...state.data, ...payload.data],
      pagination: payload.pagination,
      status: statusCode.isOk(payload.status)
        ? base.succeed(payload.operation)
        : base.fail(payload)
    };
  case base.pending(extend(TYPE)):
    return {
      ...state,
      status: base.update()
    };
  case base.rejected(extend(TYPE)):
    return {
      ...state,
      status: base.fail(payload)
    };
  default:
    return state;
  }
};

export const reducer = (
  state = {
    data: null,
    status: base.status(),
    pagination: null
  },
  action
) => baseReducer(extendFormReducer(state, action), action);
