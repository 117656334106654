import React from 'react';

import { useDrag } from 'react-dnd';
import classNames from 'classnames';

import {
  ListItem,
  ListItemGraphic,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemMeta
} from '@rmwc/list';

import { withStoreUpdate } from '../context/FormWizardContext';

import './formField.scss';

function FormField(props) {
  const onClick = () => {
    props.onClick(props.field.id);
  };
  const { field } = props;

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'dnd-field',
      fieldType: props.field.fieldType,
      fieldId: props.field.id
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  return (
    <div
      ref={drag}
      className={classNames(
        'gfs-form-field',
        'draggable',
        'filled',
        field.fieldType,
        {
          'is-dragging': isDragging
        }
      )}
    >
      {!isDragging && (
        <ListItem ripple={false} onClick={onClick}>
          <ListItemGraphic icon="drag_indicator" />
          <ListItemText>
            <ListItemPrimaryText>
              {field.name}
              {field.settings.required && ' *'}
            </ListItemPrimaryText>
            <ListItemSecondaryText>
              {`${field.fieldType} / ${field.handle}`}
            </ListItemSecondaryText>
          </ListItemText>
          <ListItemMeta icon="edit" />
        </ListItem>
      )}
    </div>
  );
}

export default withStoreUpdate(FormField);
