import React from 'react';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import {
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent
} from '@rmwc/drawer';

import Drawer from 'visuals/container/Drawer'

import AppLayout, { Content, LowerContent } from 'layout/AppLayout';
import InputTypeBrowser from './components/InputTypeBrowser';
import FormVisualizer from './components/FormVisualizer';
import FieldEditor from './components/fieldTypeOptions/FieldEditor';
import { FormWizardContextProvider } from './context/FormWizardContext';
import Header from './components/Header';

class FormWizard extends React.PureComponent {
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <FormWizardContextProvider>
          <AppLayout>
            <Drawer style={{ height: 'inherit' }}>
              <DrawerHeader>
                <DrawerTitle>Formularfeldtypen</DrawerTitle>
                <DrawerSubtitle>Basisfeldtypen für Formular</DrawerSubtitle>
              </DrawerHeader>
              <DrawerContent>
                <InputTypeBrowser />
              </DrawerContent>
            </Drawer>
            <Content>
              <Header />
              <LowerContent>
                <FormVisualizer />
              </LowerContent>
            </Content>
            <Drawer>
              <DrawerContent>
                <FieldEditor />
              </DrawerContent>
            </Drawer>
          </AppLayout>
        </FormWizardContextProvider>
      </DndProvider>
    );
  }
}

export default FormWizard;
