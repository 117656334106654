import React from 'react';

import get from 'lodash.get';
import { Typography } from '@rmwc/typography';

import { Button } from '@rmwc/button';
import { Text, Select, Checkbox } from 'common/components/input';
import Tooltip from 'common/components/Tooltip';
import { FormContainer, FormTitleBar } from 'common/components/FormContainer';
import Flexbox from 'visuals/container/Flexbox';
import Box from 'visuals/container/Box';
import SaveButtonContainer from 'common/components/SaveButtonContainer';

import { withFormik } from 'formik';

import mailSettingTypes from '../../reference/mailSettingTypes';
import mailSettingEncryptions from '../../reference/mailSettingEncryptions';


const ProjectForm = props => {
  const { isSubmitting, handleSubmit, values } = props;
  const isSmtpAuthEnabled = !get(
    values,
    'project.attributes.emailSettings.smtpAuth'
  );
  return (
    <FormContainer>
      <Flexbox column spaceBetween type="form" onSubmit={handleSubmit}>
        <Box>
          <FormTitleBar>
            <Typography use="headline6">Projekt bearbeiten</Typography>
          </FormTitleBar>
          <Box mt="sm">
            <Tooltip overlay="Konvention: Business Name">
              <Text label="Projekt Name" name="project.attributes.name" />
            </Tooltip>
            <Tooltip overlay="Diese Informationen sind im Postmark beim Server unter 'Setup Instructions' - 'SMTP' zu finden.">
              <div>
                <Text
                  name="project.attributes.emailSettings.secretKey"
                  label="Secret Key"
                />                
                <Select
                  label="Mail Typ"
                  name="project.attributes.emailSettings.type"
                  options={mailSettingTypes}
                />
                <Text
                  name="project.attributes.emailSettings.host"
                  label="Host"
                />

                <Text
                  name="project.attributes.emailSettings.port"
                  label="Port"
                  type="number"
                />

                <Select
                  label="Verschlüsselung"
                  name="project.attributes.emailSettings.encryption"
                  options={mailSettingEncryptions}
                />

                <Checkbox name="project.attributes.emailSettings.smtpAuth">
                  SMTP Auth
                </Checkbox>
                <Text
                  disabled={isSmtpAuthEnabled}
                  name="project.attributes.emailSettings.username"
                  label="Username"
                />
                <Text
                  disabled={isSmtpAuthEnabled}
                  name="project.attributes.emailSettings.password"
                  type="password"
                  label="Password"
                />
              </div>
            </Tooltip>
          </Box>
        </Box>
        <SaveButtonContainer mt="md">
          <Flexbox flexEnd p="xs">
            <Button disabled={isSubmitting} type="submit" raised>
              Speichern
            </Button>
          </Flexbox>
        </SaveButtonContainer>
      </Flexbox>
    </FormContainer>
  );
};

export default withFormik({
  handleSubmit: ({ project }, { setSubmitting, props }) => {
    props.onSubmit(project);
    setSubmitting(false);
  }
})(ProjectForm);
