import { createHashHistory } from 'history';
import {
  push,
  replace as replaceRRR,
  goBack as goBackRRR
} from 'react-router-redux';

const history = createHashHistory();

export const forward = dispatch => endpoint => dispatch(push(endpoint));
export const replace = dispatch => endpoint => dispatch(replaceRRR(endpoint));
export const goBack = dispatch => endpoint => dispatch(goBackRRR(endpoint));

export default history;
