import React from 'react';

import { Elevation } from '@rmwc/elevation';

import './simpleLayout.scss';

export const Content = ({ children }) => (
  <Elevation z="4" className="box">
    {children}
  </Elevation>
);

export default ({ children }) => <div className="container">{children}</div>;
