import { reducer as reducerHelper, selector } from 'rejuice-redux';
import { getOperation, postOperation } from 'base/requestHelper';
import { toURIFormat } from 'common/helpers/request';

const TYPE = 'project.duplicateForm.model';

export const loadForms = dispatch => projectId => {
  const params = {
    filter: {
      projectId
    }
  };
  const uriParams = toURIFormat(params);
  dispatch({
    type: TYPE,
    payload: getOperation('/api/v1/forms?' + uriParams)
  });
};

export const duplicateForm = (token, targetProjectId, formId) => {
  return postOperation(`/api/v1/forms/${formId}/duplicate`, {
    body: JSON.stringify({ _token: token, targetProjectId })
  });
};

const baseSelectors = selector.generateSelectors(
  state => state.project.duplicateForm.model,
  []
);
export const selectors = {
  ...baseSelectors
};

export const reducer = reducerHelper.defaultReducer(TYPE);
